<template>
<el-dialog v-model="visible" title="诊所负责人详情" :before-close="close">
  <div class="dialog-body clinic-partner-detail" v-loading="loading">
    <div class="ccpd-title">基本信息</div>
    <table class="ccpd-base-info-table my-table">
      <tr>
        <th>姓名</th>
        <td>{{viewData.txt_realname}}</td>
        <th>预留手机号</th>
        <td>{{viewData.txt_mobile}}</td>
      </tr>
      <tr>
        <th>申请地区</th>
        <td>{{viewData.txt_area_name}}</td>
        <th>申请日期</th>
        <td>{{viewData.dt_apply||"- -"}}</td>
      </tr>
      <tr>
        <th>身份证号</th>
        <td colspan="3">{{viewData.identityCardNo}}</td>
      </tr>
      <tr>
        <th>银行卡号</th>
        <td colspan="3">{{viewData.bankCardNo}}</td>
      </tr>
      <tr>
        <th>开户行信息</th>
        <td colspan="3">{{viewData.bankName}}</td>
      </tr>
      <tr>
        <th>微信登录地址</th>
        <td colspan="3">{{viewData.txt_wechat_address}}</td>
      </tr>
      <tr>
        <th>微信登录坐标</th>
        <td colspan="3">{{viewData.txt_wechat_coordinate}}</td>
      </tr>
      <tr>
        <th>诊所名称</th>
        <td colspan="3">{{viewData.txt_clinic_name}}</td>
      </tr>
      <tr>
        <th>诊所地址</th>
        <td colspan="3">{{viewData.txt_address}}</td>
      </tr>
      <tr>
        <th>审核状态</th>
        <td>
          <template v-if="viewData.txt_verify_status==1">已通过</template>
          <template v-else-if="viewData.txt_verify_status==2">未通过</template>
          <template v-else>审核中</template>
        </td>
        <th>审核时间</th>
        <td>{{viewData.dt_verify}}</td>
      </tr>
      <tr class="pics-part-title">
        <th colspan="4">资质图片</th>
      </tr>
      <template v-if="picsData.length!=0">
        <tr v-for="(item, index) in picsData" :key="index" class="tr-has-image">
          <template v-if="item.txt_bucket_name.includes('identitycard-principal')">
            <th>{{item.txt_qualification_name}} - {{item.txt_image_params=='face'?'正面':'反面'}}</th>
          </template>
          <template v-else>
            <th>{{item.txt_qualification_name}}</th>
          </template>
          <td colspan="3">
            <el-image :src="item.txt_image_url" :preview-src-list="[item.txt_image_url]" :initial-index="4" fit="cover" />
          </td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td colspan="4">暂无</td>
        </tr>
      </template>
    </table>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close" type="info">关 闭</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 市级合伙人详情
import { ElMessage } from 'element-plus';
import { onMounted, reactive, toRefs } from 'vue';
import { getClinicQualification, getAgentDetail } from "api/apis.js";
export default {
  props: { detailData: { type: Object } },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      loading: false,
      viewData: {},
      picsData: []
    });

    const close = () => {
      ctx.emit("close");
    };

    // 获取详情
    const getContractList = () => {
      state.loading = true;
      getAgentDetail({
        agent_id: prop.detailData.t_agent_id
      }).then(response => {
        if (response.code == 200) {
          state.viewData = response.data;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 获取诊所资质图片
    const getPics = () => {
      getClinicQualification({
        t_agent_id: prop.detailData.t_agent_id
      }).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.picsData = response.data;
          } else {
            state.picsData = [];
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    onMounted(() => {
      getContractList();
      getPics();
    })

    return {
      ...toRefs(state),
      close,
      getContractList,
      getPics
    }
  }
}
</script>

<style lang="scss" scoped>
.clinic-partner-detail {
  .ccpd-title {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .ccpd-base-info-table {
    .pics-part-title {
      height: 50px;
      font-size: 18px;
    }

    .tr-has-image {
      .el-image {
        height: 200px;
      }
    }
  }
}
</style>
