<template>
<el-dialog v-model="visible" title="合同列表" :before-close="close">
  <div class="dialog-body clinic-partner-contract-list" v-loading="loading">
    <table class="cpcl-base-info-table my-table">
      <tr>
        <th>合同名称</th>
        <th>合同发送日期</th>
        <th>诊所是否签署</th>
        <th>省级是否签署</th>
        <th>合同状态</th>
        <th>合同生效时间</th>
      </tr>
      <tr v-for="(item, index) in viewData" :key="index">
        <td class="has-under-line" @click="showContract(item)">{{item.txt_contract_name}}</td>
        <td>{{item.dt_contract_create||"- -"}}</td>
        <td>
          <template v-if="item.t_level4_status==1">未签署</template>
          <template v-else-if="item.t_level4_status==2">已签署</template>
          <template v-else>- -</template>
        </td>
        <td>
          <template v-if="item.t_level1_status==1">未签署</template>
          <template v-else-if="item.t_level1_status==2">已签署</template>
          <template v-else>- -</template>
        </td>
        <td>
          <template v-if="item.txt_status=='-1'">未发送</template>
          <template v-else-if="item.txt_status==1">未签署</template>
          <template v-else-if="item.txt_status==2">已失效</template>
          <template v-else-if="item.txt_status==3">已生效</template>
          <template v-else-if="item.txt_status==4">已过期</template>
        </td>
        <td>{{item.dt_effect||"- -"}}</td>
      </tr>
    </table>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close" type="info">关 闭</el-button>
    </div>
  </template>
  <contract v-if="compShow" :viewData="compData" @close="()=>{compShow=false;compData=new Object();}" />
</el-dialog>
</template>

<script>
// 市级合伙人详情
import Contract from "./Contract.vue";
import { ElMessage } from 'element-plus';
import { onMounted, reactive, toRefs } from 'vue';
import { getAgentContractList } from "api/apis.js";
export default {
  props: { contractData: { type: Object } },
  components: {
    Contract,
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      loading: false,
      viewData: {},
      compData: {},
      compShow: false
    });

    const close = () => {
      ctx.emit("close");
    };

    // 获取详情
    const getContractList = () => {
      state.loading = true;
      getAgentContractList({
        agent_id: prop.contractData.t_agent_id
      }).then(response => {
        if (response.code == 200) {
          state.viewData = response.data;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 预览合同
    const showContract = item => {
      state.compData.txt_contract_id = item.txt_contract_id;
      state.compData.agentid = prop.contractData.t_agent_id;
      state.compShow = true;
    }

    onMounted(() => {
      getContractList();
    })

    return {
      ...toRefs(state),
      close,
      getContractList,
      showContract
    }
  }
}
</script>

<style lang="scss" scoped>
.clinic-partner-contract-list {
  .cpcl-base-info-table {
    .has-under-line {
      cursor: pointer;
      color: #3a86fe;
      text-decoration: underline;
    }
  }
}
</style>
